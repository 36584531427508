import React from 'react';
import '../assets/css/main.css';
import { Routes, Route } from 'react-router-dom';
import Layout from './Layout';
import "bootstrap/dist/css/bootstrap.min.css";
import Home from './Home';
import Detail from './Detail';
import Services from './Services';
import About from './About';
import CreateProperty from './CreateProperty';
import PropertyEdit from './PropertyEdit';
import Modal from './Modal';
import Register from './Register';
import Login from './Login';
import Logout from './Logout';
import RecoverPass from './RecoverPass';

function App() {
  return (
   <div>
  
   
    <Routes>
    <Route path="/" element={<Layout />}>
      <Route  index element={<Home />} />
      <Route path='/property/create/' element={<CreateProperty />} />
      <Route path='/property/:propertyId/edit' element={<PropertyEdit />} />
      <Route path='/property/:propertyId/remove' element={<Modal />} />
      <Route path='/property/:propertyId/' element={<Detail />} />
      <Route path='/services/' element={<Services />} />
      <Route path='/about/' element={<About />} />
      <Route path='/register/' element={<Register />} />
      <Route path='/login/' element={<Login />} />
      <Route path='/logout/' element={<Logout />} />
      <Route path='/recoverpass/' element={<RecoverPass />} />
    </Route>
    </Routes>  
    </div>
  );
}

export default App;
