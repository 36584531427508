import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom"

import CircularProgress from '@mui/joy/CircularProgress';
import bcrypt from "bcryptjs-react";

function RecoverPass() {

    const [errorMessages, setErrorMessages] = useState({});

    const [users, setUsers] = useState([])

    const [code, setCode] = useState(0);
    const [emailSent, setEmailSent] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [codigo, setCodigo    ] = useState('');

    useEffect(() => {

        fetch('/api/users')
            .then(response => response.json())
            .then(users => {

                setUsers(users.data)
            })
            .catch(function (e) {
                console.log('la consulta devolvió un error')
                console.log(e)
            })
    }, [])

    const navigate = useNavigate();

    const errors = {
        email: "Usuario no registrado",
        codigo: "El código no coincide",
        password: "El password no coincide",
        largopass: "El password tiene que tener al menos ocho caracteres"
    };

    const handleChange = (e) => {
        const { target } = e;
        const { name, value } = target;
        switch (name) {
            case 'email':
                setEmail(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 'passwordConfirm':
                setConfirm(value);
                break;
            case 'codigo':
                setCodigo(value);
                break;
        }
    }



    const handleSubmit = (event) => {
        //Prevent page reload
        event.preventDefault();
     
        if (!emailSent) {


            function codigoAleatorio(min, max) {
                min = Math.ceil(min);
                max = Math.floor(max);
                return Math.floor(Math.random() * (max - min) + min);
            }


            // Find user to recover password
            const userDB = users.find((user) => user.email === email);
            var codigoPass = codigoAleatorio(10000, 99999)
           
            if (userDB) {
                setCode(codigoPass)
                let params = {
                    email: email,
                    code: codigoPass
                }
                let body = Object.keys(params)
                    .map((key) => { return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]); })
                    .join('&');

                fetch('/api/users/codeRecovery', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
                    body: body
                })
                    .then(response => response.json())
                    .then(respuesta => {
                        console.log(respuesta)

                    })
                    .catch(function (e) {
                        console.log(e)
                    })



                // enviar correo con ese código
                setEmailSent(true)
            } else { setErrorMessages({ name: "email", message: errors.email }); }
        } else {
         

            if (parseInt(codigo) === code) {
                if (password === confirm) {
                    if (password.length > 7) {

                        const salt = bcrypt.genSaltSync(10);
                        const contrasena = bcrypt.hashSync(password, salt);
                        let params={
                            email:email,
                            password:contrasena,
                       
                        }
                        
                        let body = Object.keys(params)
                        .map((key) => { return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]); })
                        .join('&');

                        fetch('/api/users/recoverypass', {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
                            body: body
                        })
                            .then(response => response.json())
                            .then(respuesta => {
                                console.log(respuesta)

                            })
                            .catch(function (e) {
                                console.log(e)
                            })

                        navigate("/login");

                    } else {
                        setErrorMessages({ name: "largopass", message: errors.largopass });
                    }
                } else {
                    // Invalid password Comfirm
                    setErrorMessages({ name: "password", message: errors.password });
                }
            } else {
                // Invalid code
                setErrorMessages({ name: "codigo", message: errors.codigo });
            }

        }

    };

    // Generate JSX code for error message
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="text-danger">{errorMessages.message}</div>
        );
   
    return (

        <>
            {users.length === 0 && <div className="row justify-content-center mt-5">
                <CircularProgress />
            </div>}
            {users.length !== 0 && (

                <div className=" my-5">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <h2>Recuperación de Contraseña</h2>
                            <form onSubmit={handleSubmit}>

                                <div className="row">
                                    <div className="col-md-6 my-2">
                                        <div className="form-group">
                                            <label><b>Correo electrónico:</b></label>
                                            <input 
                                            type="text" 
                                            name="email" 
                                            value={email} 
                                            onChange={handleChange} 
                                            className="form-control" required />
                                            <div className="text-danger">
                                                {renderErrorMessage("email")}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <div className="form-group">

                                            <div className="text-danger">
                                                {renderErrorMessage("password")}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <div className="form-group form-check">
                                            {/* <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" name="remember_user" />Recordar mi usuario
                                    </label> */}
                                        </div>
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <div className="form-group form-check">
                                            <label className="form-check-label">
                                                Recibiras un email con un código que debes colocar aquí
                                            </label>
                                        </div>
                                    </div>
                                    {emailSent === true && (
                                        <>
                                            <div className="col-md-6 my-2">
                                                <div className="form-group">
                                                    <label><b>Codigo:</b></label>
                                                    <input type="text" 
                                                    name="codigo"
                                                    value={codigo}
                                                    onChange={handleChange} 
                                                    className="form-control" required />
                                                    <div className="text-danger">
                                                        {renderErrorMessage("codigo")}
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-6 my-1">
                                                <div className="form-group">
                                                    <label><b>Password:</b></label>
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        value={password}
                                                        onChange={handleChange}
                                                        className="form-control" required
                                                    />

                                                    <div className="text-danger">
                                                        {renderErrorMessage("largopass")}
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-6 my-1">
                                                <div className="form-group">
                                                    <label><b>Confirmar Password:</b></label>
                                                    <input
                                                        type="password"
                                                        name="passwordConfirm"
                                                        value={confirm}
                                                        onChange={handleChange}
                                                        className="form-control" required
                                                    />

                                                    <div className="text-danger">
                                                        {renderErrorMessage("password")}
                                                    </div>

                                                </div>
                                            </div>
                                        </>)}
                                    {emailSent === false && (
                                        <div className="col-12 my-3">
                                            <button type="submit" className="btn btn-warning" >Enviar Email</button>
                                        </div>
                                    )}
                                    {emailSent === true && (
                                        <div className="col-12 my-3">
                                            <button type="submit" className="btn btn-warning" >Cambiar Contraseña</button>
                                        </div>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            )}
        </>
    )


}
export default RecoverPass;