import React from 'react';

import FormContact from './FormContact';

function DetailForm({property}) {
    
    
    console.log(property)
    return (
        <React.Fragment>

        
<div className='div1'>
<h3>Consultar por esta propiedad</h3>
              <FormContact property={property}/>

        </div>
        </React.Fragment >
    )
}



export default DetailForm