import React from 'react';
import SliderMain from './SliderMain';
import FormSearch from './FormSearch';


function Home() {
    return (
        <React.Fragment>

        <SliderMain/>
        <FormSearch/>
      
        </React.Fragment >
    )
}



export default Home