import React from "react";
import { NavLink } from 'react-router-dom';
import '../assets/css/slider.css';
import { motion, useAnimationControls } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faToilet, faVectorSquare, faCar, faCircleArrowRight, faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';



const SliderProp = ({ propiedades }) => {
    const [position, setPosition] = useState(0);
    const [visibleNext, setVisibleNext] = useState(true);
    const [visiblePrev, setVisiblePrev] = useState(false);
    const controls = useAnimationControls();

   



    const moveRight = () => {

        controls.start({
            x: (position - 500),
            transition: { duration: 1 }
        })
    }

    const moveLeft = () => {
        controls.start({
            x: (position + 500),
            transition: { duration: 1 }
        })


    }
    function onUpdate(latest) {
        setPosition(latest.x)
        if (latest.x >= 0) {
            setVisiblePrev(false);
            setVisibleNext(true);
        } else {
            setVisibleNext(true);
            setVisiblePrev(true);
        } if (latest.x <= -propiedades.length * 400) {
            setVisibleNext(false);
        }
    }
    //
   
    return (
        <>
            <motion.div className='slider-container'>
                <h2>Propiedades</h2>
                <motion.div className='slider' animate={controls} drag='x' dragConstraints={{ right: 0, left: -propiedades.length * 650 }} onUpdate={onUpdate} >

                    {propiedades.map(propiedad => (
                        <motion.div className='item card shadow' >

                            <NavLink to={`/property/${propiedad.id}/`}><img src={propiedad.image} alt="" /></NavLink>
                            <div className="modo">
                                <h2>{propiedad.mode}</h2>
                            </div>
                            <div className="id">
                                <h2># {propiedad.id}</h2>
                            </div>
                            <div className="info">
                                <div>
                                    <h2>{propiedad.money} {propiedad.price}</h2>
                                    <p>{propiedad.address}</p>
                                    <p>{propiedad.location}-{propiedad.departament}-{propiedad.province}</p>
                                </div>
                                <div className='amenities'>
                                    <FontAwesomeIcon icon={faBed} />
                                    <p>Dormitorios: {propiedad.bedroom}</p>
                                    <FontAwesomeIcon icon={faToilet} />
                                    <p>Baños: {propiedad.bathroom}</p>
                                </div>
                                <div className='amenities'>
                                    <FontAwesomeIcon icon={faVectorSquare} />
                                    <p>Sup: {propiedad.m2} m2</p>
                                    {(propiedad.garage) &&
                                        <>
                                            <FontAwesomeIcon icon={faCar} />
                                            <p>Cochera</p>
                                        </>}
                                </div>

                            </div>

                            {(sessionStorage.userId) &&
                                <>
                                    <NavLink to={`/property/${propiedad.id}/edit`}><span>Editar</span></NavLink>
                                    <NavLink to={`/property/${propiedad.id}/remove`}><span>Eliminar</span></NavLink>
                                </>}
                        </motion.div>

                    ))}
                </motion.div>
                {(visibleNext) &&
                    <div className='arrow-right shadow'>
                        <button onClick={moveRight}><FontAwesomeIcon icon={faCircleArrowRight} /></button>
                    </div>}
                {(visiblePrev) &&
                    <div className='arrow-left shadow'>
                        <button onClick={moveLeft}><FontAwesomeIcon icon={faCircleArrowLeft} /></button>
                    </div>}
            </motion.div>

        </>
    )

}

export default SliderProp;
