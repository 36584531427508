import React from 'react';

import { useParams } from "react-router-dom"
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import { useNavigate } from "react-router-dom"

function Modals() {

    const { propertyId } = useParams();
    const [show, setShow] = useState(true);
    const navigate = useNavigate()

    const handleClose = () => {
        setShow(false)
        navigate("/")
        };
    const handleRemove = () => {
        //Elimina propiedad de la DB con el ID (propertyId)
        
        fetch('api/properties/'+ propertyId + '/delete', {
            method: 'POST',
           
        })
            .then(response => response.json())
            .then(propiedad => {

                setShow(false)
                navigate("/")
              
            })
            .catch((err) => {
                console.log(err)
            })
       
        };
    return (
        <React.Fragment>

          
          <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar Propiedad</Modal.Title>
                </Modal.Header>
                <Modal.Body>¿Está seguro que desea eliminar la propiedad con ID-{propertyId}?
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn-color m-1' onClick={handleRemove}>
                    Si
                </button>
                <button className='btn-color m-1' onClick={handleClose}>
                    Cancelar
                </button>
                </Modal.Footer>
            </Modal>
        </React.Fragment >
    )
}



export default Modals;