import React from 'react';
import { useState } from 'react';


function FormContact({property}) {
    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneArea, setPhoneArea] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [sent, setSent] = useState(false);



const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    switch (name) {
        case 'name':
            setName(value);
            break;
        case 'email':
                setEmail(value);
            break;
        case 'phoneArea':
            setPhoneArea(value);
            break;
        case 'phoneNumber':
            setPhoneNumber(value);
            break;
        case 'message':
            setMessage(value);
            break;
        }
    }
    const sendEmail= (event)=>{  
        event.preventDefault();

        let params={
            name:name,
            email:email,
            phoneArea:phoneArea,
            phoneNumber:phoneNumber,
            message:message
        }

        if (property === undefined){
            console.log('entra')
            var property = [{
                id:1
            }]
            
            console.log(property)
        }
        let body = Object.keys(params)
        .map((key) => { return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]); })
        .join('&');
               
                fetch('/api/properties/' + property[0].id + '/contact', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
                    body:body
                    
                })
                    .then(response => response.json())
                    .then(respuesta => {
                        console.log(respuesta)
                        setSent(true)

                    })
                    .catch(function (e) {
                        console.log(e)
                        
                    })
       
    }
    console.log(property)
    //https://formsubmit.co/dario.hernandez@gmail.com
    return (
        <React.Fragment>


                <div>
                <form action="" method="POST">
                    <input
                        name="name"
                        className='form-control m-1'
                        value={name}
                        placeholder="Nombre y Apellido"
                        onChange={handleChange}
                    />
                    
                    <input
                        name="email"
                        className='form-control m-1'
                        value={email}
                        placeholder="Correo Electrónico"
                        onChange={handleChange}
                    />
                    <input
                        name="phoneArea"
                        className='form-control m-1'
                        value={phoneArea}
                        placeholder="0261*"
                        onChange={handleChange}
                    />
                    <input
                        name="phoneNumber"
                        className='form-control m-1'
                        value={phoneNumber}
                        placeholder="9999999*"
                        onChange={handleChange}
                    />
                     <textarea
                        name="message"
                        className='form-control m-1'
                        value={message}
                        placeholder="Mensaje*"
                        onChange={handleChange}
                    />
                    {(property) &&
                    <input type ="hidden" name ="property" value={property[0].id}></input>}
                      {(!sent) &&
                    <button className='btn-color m-1 'onClick={sendEmail}>Enviar</button>
                      }
                    </form>
                {(sent) &&
                    <div className="text-danger">Mensaje enviado</div>
                        }
                </div>
               
    
        </React.Fragment >
    )
}



export default FormContact;