import React from 'react';
import SliderDetail from './SliderDetail';
import DetailAmenities from './DetailAmenities';
import DetailForm from './DetailForm';
import DetailServices from './DetailServices';
import DetailLocation from './DetailLocation';
import { useParams } from "react-router-dom"
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/joy/CircularProgress';

function Detail() {
    const { propertyId } = useParams();
    const [property, setProperty] = useState([]);
    const [images, setImages] = useState([]);

useEffect(() => {

    // recupero el ID de la propiedad de la barra de direcciones con useParams
    // Buscar en la DB  la propiedad en /api/properties/idProperty
    

        fetch('api/properties/'+ propertyId)
            .then(response => response.json())
            .then(propiedad => {

                setProperty(propiedad.data)
              
            })
            .catch((err) => {
                console.log(err)
            })
            //Buscar en la DB las imagenes de la propiedad en /api/properties/:idProperty/images
            // Recuperar las imagines y enviarles al SliderDetail 
            fetch('api/properties/'+ propertyId +'/images')
            .then(response => response.json())
            .then(imagenes => {

                setImages(imagenes.data)
              
            })
            .catch((err) => {
                console.log(err)
            })    

    }, [])





console.log(property)

    return (
        <React.Fragment>
     {property.length === 0 && <div className="row justify-content-center ">
                                            <CircularProgress />
                                        </div>}
        
    {(property.length != 0) &&
    <> 
        <SliderDetail images={images}/>
        <DetailAmenities property={property}/>
        <div className='infoDetail'>
            <div className='div1'>
                {property[0].detail}
            </div>
            
                
            
               <DetailForm property={property}/>
            
        </div>
        <DetailServices property={property}/>
        <DetailLocation property={property}/>
    </>
    }
        </React.Fragment >
    )
}



export default Detail