import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom"
import bcrypt from "bcryptjs-react";

function Register() {

    const [errorMessages, setErrorMessages] = useState({});

    const [users, setUsers] = useState([])
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');

    useEffect(() => {

        fetch('/api/users')
            .then(response => response.json())
            .then(users => {
                setUsers(users.data)
            })
            .catch(function (e) {
                console.log(e)
            })
    }, [])

 
    const navigate = useNavigate()

    const errors = {
        email: "El usuario ya está registrado",
        password: "El password no coincide",
        largopass: "El password tiene que tener al menos ocho caracteres",
      
    };

    
    const handleChange = (e) => {
        const { target } = e;
        const { name, value } = target;

        switch (name) {
            case 'name':
                setName(value);
                break;
            case 'lastname':
                setLastname(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'phone':
                setPhone(value);
                break;
            case 'password':
                    setPassword(value);
                break;
            case 'confirm':                   
                    setConfirm(value);
                break;
            }
    }
    const handleSubmit = () => {
     
       
        // Find user login info
        const userData = users.find((user) => user.email === email);

        if (!userData) {
            if (password=== confirm) {

                if (password.length > 7) {
                       
                    const salt = bcrypt.genSaltSync(10);
                    const contrasena = bcrypt.hashSync(password, salt);
                    let params={
                        name:name,
                        lastname:lastname,
                        phone:phone,
                        email:email,
                        password:contrasena
                    }

                    let body = Object.keys(params)
                    .map((key) => { return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]); })
                    .join('&');
                    
                            fetch('/api/users/register', {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
                                body:body
                                
                            })
                                .then(response => response.json())
                                .then(respuesta => {
                                    console.log(respuesta)
    
                                })
                                .catch(function (e) {
                                    console.log(e)
                                })
    
                               // sendEmail(nombre, correo)   
                                navigate("/login")
                    


                  
                   
                } else {
                    setErrorMessages({ name: "largopass", message: errors.largopass });
                }
            } else {
                // Invalid password Comfirm
                setErrorMessages({ name: "password", message: errors.password });
            }
        } else {
            // Usuario ya existente
            setErrorMessages({ name: "email", message: errors.email });
        }
    };

    // Generate JSX code for error message
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="text-danger">{errorMessages.message}</div>
        );



    return (
        <div className=" my-5">
            <div className="row justify-content-center">
                <div className="col-md-10">
                    <h2>Formulario de registro de usuario</h2>

                
                        <div className="row">
                            <div className="col-md-6 my-1">
                                <div className="form-group">
                                    <label><b>Nombre:</b></label>
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder='Ej. Juan'
                                        className="form-control" required
                                        value={name}
                                        onChange={handleChange}
                                    />

                                    <div className="text-danger">

                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6 my-1">
                                <div className="form-group">
                                    <label><b>Apellido:</b></label>
                                    <input
                                        type="text"
                                        name="lastname"
                                        placeholder='Ej. Perez'
                                        className="form-control" required
                                        value={lastname}
                                        onChange={handleChange}
                                    />

                                    <div className="text-danger">

                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6 my-1">
                                <div className="form-group">
                                    <label><b>Teléfono:</b></label>
                                    <input
                                        type="text"
                                        name="phone"
                                        placeholder='Ej. 0261-1234567'
                                        className="form-control" required
                                        value={phone}
                                        onChange={handleChange}
                                    />

                                    <div className="text-danger">

                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6 my-1">
                                <div className="form-group">
                                    <label><b>Correo electrónico:</b></label>
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder='Ej. juanperez@gmail.com'
                                        className="form-control" required
                                        value={email}
                                        onChange={handleChange}
                                    />

                                    <div className="text-danger">
                                        {renderErrorMessage("email")}
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6 my-1">
                                <div className="form-group">
                                    <label><b>Password:</b></label>
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder='8 caracteres como mínimo'
                                        className="form-control" required
                                        value={password}
                                        onChange={handleChange}
                                    />

                                    <div className="text-danger">
                                        {renderErrorMessage("largopass")}
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6 my-1">
                                <div className="form-group">
                                    <label><b>Confirmar Password:</b></label>
                                    <input
                                        type="password"
                                        name="confirm"
                                        className="form-control" required
                                        value={confirm}
                                        onChange={handleChange}
                                    />

                                    <div className="text-danger">
                                        {renderErrorMessage("password")}
                                    </div>

                                </div>
                            </div>
                        

                           

                            <div className="col-12 my-3">
                                <button onClick={handleSubmit} className="btn-color">Registrarse</button>
                            </div>
                        </div>
                   
                </div>
            </div>
        </div>
    )
}

export default Register