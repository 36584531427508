import React from "react";
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"
import '../assets/css/slider.css';
import '../assets/css/formSearch.css'
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faToilet, faVectorSquare, faCar } from '@fortawesome/free-solid-svg-icons';
import CircularProgress from '@mui/joy/CircularProgress';
import { useNavigate } from "react-router-dom"
import {
    faDog, faFileContract, faDoorOpen, faWaterLadder, faFileInvoiceDollar,
    faDollarSign, faHouseLock, faHourglassStart, faHouseCircleCheck, faBuilding,
    faTemperatureArrowUp, faCouch, faSquare
} from '@fortawesome/free-solid-svg-icons';
import Switch from "react-switch";

const PropertyEdit = ({ propiedades }) => {
    const [modeSale, setModeSale] = useState(true);
    const [mode, setMode] = useState('');
    const [type, setType] = useState('');
    const [price, setPrice] = useState('');
    const [money, setMoney] = useState('');
    const [address, setAddress] = useState('');
    const [location, setLocation] = useState('');
    const [departament, setDepartament] = useState('');
    const [province, setProvince] = useState('');
    const [bedroom, setBedroom] = useState('');
    const [bathroom, setBathroom] = useState('');
    const [m2, setM2] = useState('');
    const [m2Total, setM2Total] = useState('');
    const [garage, setGarage] = useState('');
    const [mainImage, setMainImage] = useState(null);
    const [mainImageURL, setMainImageURL] = useState(null);
    const [gallery, setGallery] = useState([]);
    const [imagesURL, setImagesURL] = useState(null);
    const [initial, setInitial] = useState(true);
    const [detail, setDetail] = useState('');
    const [pets, setPets] = useState(false);
    const [credit, setCredit] = useState(false);
    const [qtyScope, setQtyScope] = useState('');
    const [pool, setPool] = useState(false);
    const [expenses, setExpenses] = useState(false);
    const [expensesCost, setExpensesCost] = useState('');
    const [privated, setPrivated] = useState(false);
    const [antiquity, setAntiquity] = useState('');
    const [careful, setCareful] = useState('');
    const [floors, setFloors] = useState('');
    const [heat, setHeat] = useState(false);
    const [furniture, setFurniture] = useState(false);
    const [geoLink, setGeoLink] = useState('');
    const [filename, setFilename] = useState('');
    const [updated, setUpdated] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const { propertyId } = useParams();
    const [property, setProperty] = useState([]);

    const navigate = useNavigate()

    useEffect(() => {

        fetch('api/properties/' + propertyId)
            .then(response => response.json())
            .then(propiedad => {

                setProperty(propiedad.data);
                setMainImageURL(propiedad.data[0].image);
                setMode(propiedad.data[0].mode);
                setType(propiedad.data[0].type);
                setPrice(propiedad.data[0].price);
                setMoney(propiedad.data[0].money);
                setAddress(propiedad.data[0].address);
                setLocation(propiedad.data[0].location);
                setDepartament(propiedad.data[0].departament);
                setProvince(propiedad.data[0].province);
                setBedroom(propiedad.data[0].bedroom);
                setBathroom(propiedad.data[0].bathroom);
                setM2(propiedad.data[0].m2);
                setM2Total(propiedad.data[0].m2Total);
                if (propiedad.data[0].garage == 1) {
                    setGarage('Si')
                }
                setDetail(propiedad.data[0].detail);
                if (propiedad.data[0].pets == 1) {
                    setPets(true)
                } else { setPets(false) }
                if (propiedad.data[0].credit == 1) {
                    setCredit(true)
                } else { setCredit(false) }
                setQtyScope(propiedad.data[0].qtyScope);
                if (propiedad.data[0].pool == 1) {
                    setPool(true)
                } else { setPool(false) }
                if (propiedad.data[0].expenses == 1) {
                    setExpenses(true)
                } else { setExpenses(false) }
                setExpensesCost(propiedad.data[0].expensesCost);
                if (propiedad.data[0].private == 1) {
                    setPrivated(true)
                } else { setPrivated(false) }
                setAntiquity(propiedad.data[0].antiquity);
                setCareful(propiedad.data[0].careful);
                setFloors(propiedad.data[0].floors);
                if (propiedad.data[0].heat == 1) {
                    setHeat(true)
                } else { setHeat(false) }
                if (propiedad.data[0].furniture == 1) {
                    setFurniture(true)
                } else { setFurniture(false) }
                setGeoLink(propiedad.data[0].geoLink);
            })
            .catch((err) => {
                console.log(err)
            })
        //Buscar en la DB las imagenes de la propiedad en /api/properties/:idProperty/images
        // Recuperar las imagines y enviarles al SliderDetail 
        fetch('api/properties/' + propertyId + '/images')
            .then(response => response.json())
            .then(imagenes => {
                var imgs = [];
                imagenes.data.forEach(element => {
                    imgs.push(element.image);
                });
                setImagesURL(imgs);
            })
            .catch((err) => {
                console.log(err)
            })




    }, [])




    //Mensajes de error
    const errors = {
        address: "Alcanzó el límite de caracteres máximos permitidos",
        detail: "Alcanzó el límite de caracteres máximos permitidos",
        location: "Alcanzó el límite de caracteres máximos permitidos",
        departament: "Alcanzó el límite de caracteres máximos permitidos",
        province: "Alcanzó el límite de caracteres máximos permitidos",
        province: "Alcanzó el límite de caracteres máximos permitidos",
        antiquity: "Alcanzó el límite de caracteres máximos permitidos",
        careful: "Alcanzó el límite de caracteres máximos permitidos",
        geoLink: "Alcanzó el límite de caracteres máximos permitidos",
    };

    // Generate JSX code for error message
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="text-danger">{errorMessages.message}</div>
        );
    //Drag & Drop
    const update = () => {
        setUpdated(!updated)
    }
    const changeImage = (e) => {

        if (e.target.files !== undefined) {
            setMainImage(e.target.files[0]);
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = (e) => {
                e.preventDefault();
                setMainImageURL(e.target.result); // le damos el binario de la imagen para mostrarla en pantalla
            };

        }
    };

    const changeImages = (e) => {

        if (e.target.files !== undefined) {
           
            if (imagesURL.length === 0 || initial) {

                var cant = 0;
                var imagenes = []

            } else {
                var cant = imagesURL.length;

                var imagenes = gallery;
            }


            var nombresArchivos = filename;
            var filesImage = [];
            for (let i = cant; i < (e.target.files.length + cant); i++) {
                const reader = new FileReader();

                nombresArchivos += (e.target.files[i - cant].name + ' ');
                setFilename(nombresArchivos)
                filesImage[i] = e.target.files[i - cant]
                reader.readAsDataURL(e.target.files[i - cant]);
                reader.onload = (e) => {
                    e.preventDefault();
                    imagenes[i] = e.target.result; // le damos el binario de la imagen para mostrarla en pantalla
                    setGallery(filesImage);
                    setImagesURL(imagenes);
                    setInitial(false);
                }


            }

            setTimeout(() => {
                update();
            }, 1000)


        }
    };

    function isObjEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    const send = () => {

        if (isObjEmpty(errorMessages)) {

            var formData = new FormData();


            formData.append('mode', mode);
            formData.append('type', type);
            formData.append('price', price);
            formData.append('money', money);
            formData.append('address', address);
            formData.append('location', location);
            formData.append('departament', departament);
            formData.append('province', province);
            formData.append('bedroom', bedroom);
            formData.append('bathroom', bathroom);
            formData.append('m2', m2);
            formData.append('m2Total', m2Total);
            formData.append('detail', detail);
            formData.append('qtyScope', qtyScope);
            formData.append('expensesCost', expensesCost);
            formData.append('antiquity', antiquity);
            formData.append('careful', careful);
            formData.append('floors', floors);
            formData.append('geoLink', geoLink);
            if (garage === 'Si') {
                formData.append('garage', 1);
            } else formData.append('garage', 0);
            if (pets) {
                formData.append('pets', 1);
            } else formData.append('pets', 0);
            if (credit) {
                formData.append('credit', 1);
            } else formData.append('credit', 0);
            if (pool) {
                formData.append('pool', 1);
            } else formData.append('pool', 0);
            if (expenses) {
                formData.append('expenses', 1);
            } else formData.append('expenses', 0);
            if (privated) {
                formData.append('private', 1);
            } else formData.append('private', 0);
            if (heat) {
                formData.append('heat', 1);
            } else formData.append('heat', 0);
            if (furniture) {
                formData.append('furniture', 1);
            } else formData.append('furniture', 0);
            gallery.forEach(element => {
                formData.append('gallery', element);
            });


           
                formData.append('image', mainImage);

                fetch('/api/properties/'+propertyId+'/edit', {
                    method: 'POST',
                    body: formData
                })
                    .then(response => response.json())
                    .then(respuesta => {
                        console.log(respuesta)

                    })
                    .catch(function (e) {
                        console.log(e)
                    })


                navigate("/")
            
        }
    }

    //Setea Alquiler  
    const buttonRent = () => {
        setModeSale(false);
        setMode('Alquiler')

    }
    //Setea Venta 
    const buttonSale = () => {
        setModeSale(true);
        setMode('Venta')

    }

    //Manejo de switchs
    const handleChangeSwitchPets = (e) => {
        setPets(!pets);

    }

    const handleChangeSwitchCredit = (e) => {
        setCredit(!credit);

    }
    const handleChangeSwitchPool = (e) => {
        setPool(!pool);

    }

    const handleChangeSwitchExpenses = (e) => {
        setExpenses(!expenses);

    }
    const handleChangeSwitchPrivated = (e) => {
        setPrivated(!privated);

    }

    const handleChangeSwitchHeat = (e) => {
        setHeat(!heat);

    }
    const handleChangeSwitchFurniture = (e) => {
        setFurniture(!furniture);

    }

    function isNum(val) {
        return !isNaN(val)
    }
    // Manejo de los eventos en el formulario
    const handleChange = (e) => {
        const { target } = e;
        const { name, value } = target;

        switch (name) {
            case 'type':
                setType(value);
                break;
            case 'price':
                if (isNum(value)) {
                    setPrice(value);
                }
                break;
            case 'money':
                setMoney(value);
                break;
            case 'address':
                if (address.length < 148) {
                    setAddress(value);
                    setErrorMessages({})
                } else {
                    setErrorMessages({ name: "address", message: errors.address })
                    setAddress(value);
                } if (address.length > 148) {
                    setErrorMessages({ name: "address", message: errors.address })
                }

                break;
            case 'location':
                if (location.length < 44) {
                    setLocation(value);;
                    setErrorMessages({})
                } else {
                    setErrorMessages({ name: "location", message: errors.location })
                    setLocation(value);
                } if (location.length > 44) {
                    setErrorMessages({ name: "location", message: errors.location })
                }

                break;
            case 'departament':
                if (departament.length < 44) {
                    setDepartament(value);;
                    setErrorMessages({})
                } else {
                    setErrorMessages({ name: "departament", message: errors.departament })
                    setDepartament(value);
                } if (departament.length > 44) {
                    setErrorMessages({ name: "departament", message: errors.departament })
                }

                break;
            case 'province':
                if (province.length < 44) {
                    setProvince(value);;
                    setErrorMessages({})
                } else {
                    setErrorMessages({ name: "province", message: errors.province })
                    setProvince(value);
                } if (province.length > 44) {
                    setErrorMessages({ name: "province", message: errors.province })
                }

                break;
            case 'bedroom':
                if (isNum(value)) {
                    setBedroom(value);
                }
                break;
            case 'bathroom':
                if (isNum(value)) {
                    setBathroom(value);
                }
                break;
            case 'm2':
                setM2(value);
                break;
            case 'm2Total':
                setM2Total(value);
                break;
            case 'garage':
                setGarage(value);
                break;
            case 'detail':
                if (detail.length < 1999) {
                    setDetail(value);;
                    setErrorMessages({})
                } else {
                    setErrorMessages({ name: "detail", message: errors.detail })
                    setDetail(value);
                } if (detail.length > 1999) {
                    setErrorMessages({ name: "detail", message: errors.detail })
                }

                break;
            case 'qtyScope':
                if (isNum(value)) {
                    setQtyScope(value);
                }
                break;
            case 'expensesCost':
                if (isNum(value)) {
                    setExpensesCost(value);
                }
                break;
            case 'antiquity':
                if (antiquity.length < 44) {
                    setAntiquity(value);;
                    setErrorMessages({})
                } else {
                    setErrorMessages({ name: "antiquity", message: errors.antiquity })
                    setAntiquity(value);
                } if (antiquity.length > 44) {
                    setErrorMessages({ name: "antiquity", message: errors.antiquity })
                }

                break;
            case 'careful':
                if (careful.length < 44) {
                    setCareful(value);;
                    setErrorMessages({})
                } else {
                    setErrorMessages({ name: "careful", message: errors.antiquity })
                    setCareful(value);
                } if (careful.length > 44) {
                    setErrorMessages({ name: "careful", message: errors.careful })
                }

                break;
            case 'floors':
                if (isNum(value)) {
                    setFloors(value);
                }
                break;
            case 'geoLink':
                if (geoLink.length < 499) {
                    setGeoLink(value);
                    setErrorMessages({})
                } else {
                    setErrorMessages({ name: "geoLink", message: errors.geoLink })
                    setGeoLink(value);
                } if (geoLink.length > 499) {
                    setErrorMessages({ name: "geoLink", message: errors.geoLink })
                }

                break;
        }
    }



    return (
        <>
            {!property && <div className="row justify-content-center mt-5">
                <CircularProgress />
            </div>}
            {property && (
                <>
                    <div className="image-upload-wrap">
                        <input
                            className="file-upload-input"
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                                changeImage(e);
                            }}
                        />
                        <div className="text-information">
                            <h5>Arrastrar y Soltar aquí la imagen de Portada o clickear para cargar</h5>
                        </div>
                    </div>


                    <div className='main-container'>

                        <div className='itemCreate card shadow'>

                            <img src={mainImageURL} alt="" />
                            <div className="modo">
                                <h2>{mode}</h2>
                            </div>

                            <div className="info">
                                <div>
                                    <h2>{money} {price}</h2>
                                    <p>{address}</p>
                                    <p>{location}-{departament}-{province}</p>
                                </div>
                                <div className='amenities'>
                                    <FontAwesomeIcon icon={faBed} />
                                    <p>Dormitorios: {bedroom}</p>
                                    <FontAwesomeIcon icon={faToilet} />
                                    <p>Baños: {bathroom}</p>
                                </div>
                                <div className='amenities'>
                                    <FontAwesomeIcon icon={faSquare} />
                                    <p>Sup Cubierta: {m2} m2</p>
                                    {(garage === 'Si') &&
                                        <>
                                            <FontAwesomeIcon icon={faCar} />
                                            <p>Cochera</p>
                                        </>}

                                </div>

                            </div>

                        </div>
                        <div className="createmode">
                            <button className={`${modeSale ? "" : "active"} btn`} onClick={buttonRent}>Alquiler
                            </button>
                            <button className={`${modeSale ? "active" : ""} btn`} onClick={buttonSale}>Venta
                            </button>

                            <select
                                name="type"
                                className=' form-control '
                                value={type}
                                placeholder="Tipo"
                                onChange={handleChange}
                            >   <option>Tipo</option>
                                <option>Casa</option>
                                <option>Departamento</option>
                                <option>Oficina</option>
                                <option>Lote</option>
                                <option>Lote Comercial</option>
                                <option>Finca</option>
                                <option>Loteo</option>
                                <option>Duplex</option>
                                <option>Triplex</option>
                                <option>Edificio</option>
                                <option>Cochera</option>
                                <option>Cabaña</option>
                            </select>

                            <input
                                name="price"
                                className='form-control'
                                value={price}
                                placeholder="Precio"
                                onChange={handleChange}
                            />
                            <select
                                name="money"
                                className=' form-control '
                                value={money}
                                placeholder="Moneda"
                                onChange={handleChange}
                            >   <option>Moneda</option>
                                <option>$</option>
                                <option>U$S</option>
                            </select>
                            <input
                                name="address"
                                className='form-control'
                                value={address}
                                placeholder="Dirección"
                                onChange={handleChange}
                            />
                            <div className="text-danger">
                                {renderErrorMessage("address")}
                            </div>
                            <input
                                name="location"
                                className='form-control'
                                value={location}
                                placeholder="Localidad"
                                onChange={handleChange}
                            />
                            <div className="text-danger">
                                {renderErrorMessage("location")}
                            </div>
                            <input
                                name="departament"
                                className='form-control'
                                value={departament}
                                placeholder="Departamento"
                                onChange={handleChange}
                            />
                            <div className="text-danger">
                                {renderErrorMessage("departament")}
                            </div>
                            <input
                                name="province"
                                className='form-control'
                                value={province}
                                placeholder="Provincia"
                                onChange={handleChange}
                            />
                            <div className="text-danger">
                                {renderErrorMessage("province")}
                            </div>
                            <input
                                name="bedroom"
                                className='form-control'
                                value={bedroom}
                                placeholder="Dormitorios"
                                onChange={handleChange}
                            />
                            <input
                                name="bathroom"
                                className='form-control'
                                value={bathroom}
                                placeholder="Baños"
                                onChange={handleChange}
                            />
                            <input
                                name="m2"
                                className='form-control'
                                value={m2}
                                placeholder="Sup. Cubierta"
                                onChange={handleChange}
                            />
                            <input
                                name="m2Total"
                                className='form-control'
                                value={m2Total}
                                placeholder="Sup. Total"
                                onChange={handleChange}
                            />
                            <select
                                name="garage"
                                className=' form-control '
                                value={garage}
                                placeholder="Cochera"
                                onChange={handleChange}
                            >   <option>Cochera</option>
                                <option>Si</option>
                                <option>No</option>
                            </select>

                        </div>

                    </div>

                    <div className="image-upload-wrap">
                        <input
                            className="file-upload-input"
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={(e) => {
                                changeImages(e);
                            }}

                        />
                        {(filename !== '') && <span>{filename}</span>}
                        <div className="text-information">
                            <h5>Arrastrar Todas las imagenes del detalle y Soltar aquí o clickear para cargar</h5>
                        </div>
                    </div>
                    {!imagesURL && <div className="row justify-content-center mt-5">
                        <CircularProgress />
                    </div>}
                    {imagesURL && (
                        <>
                            <motion.div className='slider-container'>
                                <motion.div className='slider' drag='x' dragConstraints={{ right: 0, left: -imagesURL.length * 500 }} >
                                    {imagesURL.map(image => (
                                        <motion.div className='item'>
                                            <img src={image} alt="" />
                                        </motion.div>
                                    ))}
                                </motion.div>
                            </motion.div>
                        </>)}
                    <div className='detail-services'>

                        <div className=''>
                            <h5>Detalle</h5>
                            <textarea
                                name="detail"
                                className='form-control m-1'
                                value={detail}
                                placeholder="Detalle*"
                                onChange={handleChange}
                            />
                            <div className="text-danger">
                                {renderErrorMessage("detail")}
                            </div>
                        </div>
                        <div className=''>
                            <h5>Servicios</h5>
                            <div className='containerServices'>



                                <div className='services'>
                                    <Switch
                                        checked={pets}
                                        onChange={handleChangeSwitchPets}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <FontAwesomeIcon icon={faDog} />
                                    <p>Mascotas: {pets ? 'Si' : 'No'}</p>
                                    <Switch
                                        checked={credit}
                                        onChange={handleChangeSwitchCredit}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <FontAwesomeIcon icon={faFileContract} />
                                    <p>Apto Crédito Hipetacario: {credit ? 'Si' : 'No'}</p>


                                    <FontAwesomeIcon icon={faDoorOpen} />
                                    <input
                                        name="qtyScope"
                                        className='form-control'
                                        value={qtyScope}
                                        placeholder="Ambientes"
                                        onChange={handleChange}
                                    />
                                    <p>Cant. ambientes: {qtyScope}</p>
                                    <Switch
                                        checked={pool}
                                        onChange={handleChangeSwitchPool}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <FontAwesomeIcon icon={faWaterLadder} />
                                    <p>Piscina: {pool ? 'Si' : 'No'} </p>

                                </div>
                                <div className='services'>
                                    <Switch
                                        checked={expenses}
                                        onChange={handleChangeSwitchExpenses}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <FontAwesomeIcon icon={faFileInvoiceDollar} />
                                    <p>Expensas: {expenses ? 'Si' : 'No'} </p>

                                    <FontAwesomeIcon icon={faDollarSign} />
                                    <input
                                        name="expensesCost"
                                        className='form-control'
                                        value={expensesCost}
                                        placeholder="Expensas"
                                        onChange={handleChange}
                                    />
                                    <p>Valor Expensas: $ {expensesCost} </p>
                                    <Switch
                                        checked={privated}
                                        onChange={handleChangeSwitchPrivated}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <FontAwesomeIcon icon={faHouseLock} />
                                    <p>Barrio Privado: {privated ? 'Si' : 'No'}</p>

                                    <FontAwesomeIcon icon={faHourglassStart} />
                                    <input
                                        name="antiquity"
                                        className='form-control'
                                        value={antiquity}
                                        placeholder="Antigüedad"
                                        onChange={handleChange}
                                    />
                                    <div className="text-danger">
                                        {renderErrorMessage("antiquity")}
                                    </div>
                                    <p>Antigüedad: {antiquity}</p>
                                </div>

                                <div className='services'>
                                    <FontAwesomeIcon icon={faHouseCircleCheck} />
                                    <input
                                        name="careful"
                                        className='form-control'
                                        value={careful}
                                        placeholder="Conservación"
                                        onChange={handleChange}
                                    />
                                    <div className="text-danger">
                                        {renderErrorMessage("careful")}
                                    </div>
                                    <p>Estado de Consevación: {careful}</p>

                                    <FontAwesomeIcon icon={faBuilding} />
                                    <input
                                        name="floors"
                                        className='form-control'
                                        value={floors}
                                        placeholder="Plantas"
                                        onChange={handleChange}
                                    />
                                    <p>Plantas: {floors} </p>
                                    <Switch
                                        checked={heat}
                                        onChange={handleChangeSwitchHeat}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <FontAwesomeIcon icon={faTemperatureArrowUp} />
                                    <p>Calefacción Central: {heat ? 'Si' : 'No'} </p>
                                    <Switch
                                        checked={furniture}
                                        onChange={handleChangeSwitchFurniture}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <FontAwesomeIcon icon={faCouch} />
                                    <p>Amoblado: {furniture ? 'Si' : 'No'} </p>
                                </div>


                            </div>

                        </div>
                        <div >
                            <h5>Ubicación </h5>
                            <input
                                name="geoLink"
                                className='form-control '
                                value={geoLink}
                                placeholder="Copie el Link de Google Maps"
                                onChange={handleChange}
                            />
                            <div className="text-danger">
                                {renderErrorMessage("geoLink")}
                            </div>
                        </div>
                    </div>
                    <div className='confirmar'>
                        <button className='btn-color m-auto' onClick={send}>Confirmar</button>
                    </div>
                </>)}
        </>
    )

}

export default PropertyEdit;
