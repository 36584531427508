import React from 'react';

import {  useState } from 'react';

import PropertyMain from './PropertyMain';


function CreateProperty() {
    
   






    return (
        <React.Fragment>
          
        <PropertyMain/>  
      
      
       
            
                
            
          
            
        
        {/* <DetailServices property={property}/>
        <DetailLocation property={property}/>  */}
    
        </React.Fragment >
    )
}



export default CreateProperty;